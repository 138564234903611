<script setup lang="ts">
  import Image from './Partials/Media/Image.vue'
  import Qbrick from './Widgets/Composite/QBrickVideo.vue'
  import type { ImageData } from '../types/widgets/imageTypes'

  const componentMap: ComponentMap = {
    image: Image,
    video: Qbrick,
  }

  const {
    image,
    video_url,
    count,
    hide_texts,
    heroImage = false,
  } = defineProps<{
    image?: ImageData | null
    video_url?: string | null
    count?: GalleryImageCount | null
    hide_texts?: boolean | null
    heroImage?: boolean
  }>()

  const data = computed(() => {
    return {
      id: video_url,
      is_playlist: false,
    }
  })

  const componentType = computed(() => (video_url ? 'video' : 'image'))
  const componentAttr = computed(() =>
    componentType.value === 'video'
      ? { video_url, heroImage }
      : { image, count, hide_texts, heroImage }
  )

  const selectedComponent = computed(() => componentMap[componentType.value])
</script>

<template>
  <component
    v-if="video_url || image"
    :is="selectedComponent"
    v-bind="componentAttr"
    :data="data"
  />
</template>
